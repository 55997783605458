@use '@angular/material' as mat;
@use 'sass:map';

// Define a light theme
$light-primary: mat.define-palette(mat.$indigo-palette);
$light-accent: mat.define-palette(mat.$pink-palette);
$admin-light-theme: mat.define-light-theme((
  color: (
    primary: $light-primary,
    accent: $light-accent,
  )
));

// Define a dark theme
$dark-primary: mat.define-palette(mat.$pink-palette);
$dark-accent: mat.define-palette(mat.$blue-gray-palette);
$admin-dark-theme: mat.define-dark-theme((
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
  )
));

// Define main colors
$app-color-primary: map.get(mat.$indigo-palette, 500);
$app-color-accent: map.get(mat.$pink-palette, 500);
$app-color-warn: map.get(mat.$red-palette, 500);

// Define sizes
$app-header-height: 50px;
$app-menu-width: 240px;

// $grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
$grid-xs: 0;
$grid-sm: 576px;
$grid-md: 768px;
$grid-lg: 992px;
$grid-xl: 1200px;
$grid-mobile: $grid-md;
$grid-desktop: $grid-xl;
