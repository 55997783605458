@use '@angular/material' as mat;

@import 'icons';
@import 'variables';
@import 'scrollbars';
@import 'quill/dist/quill.snow.css';
/* include bootstrap */
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/utilities/sizing';
@import 'bootstrap/scss/utilities/text';
@import 'bootstrap/scss/forms';

/* include mat.core */
@include mat.core();
/* include mat.core-theme */
@include mat.core-theme($admin-light-theme);
/* include mat.all-component-themes */
@include mat.all-component-themes($admin-light-theme);
/* include mat.all-component-typographies */
@include mat.all-component-typographies();
/* include mat.form-field-density */
@include mat.form-field-density(minimum);

/* include dark-theme */
.admin-dark-theme {
  @include mat.core-color($admin-dark-theme);
  @include mat.all-component-colors($admin-dark-theme);
}

/* include styles */
*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #212529;
  background-color: #fafafa;
}

a {
  color: $app-color-primary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $app-color-primary;
    text-decoration: underline;
  }
}

a.mdc-button,
a.card,
a.mat-card,
a.mat-mdc-card {
  text-decoration: none;
}

p {
  margin: 0 0 1rem;
}

img.ng-lazyloaded {
  animation: fadein 0.5s;
}

.text-primary {
  color: $app-color-primary;
}

.text-accent {
  color: $app-color-accent;
}

.text-warn {
  color: $app-color-warn;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.mat-mdc-card {
  @include mat.elevation(3);
}

.mat-mdc-form-field {
  width: 100%;
}

.mat-datepicker-toggle {
  .mdc-icon-button {
    position: absolute;
    top: -6px;
    right: 0;
  }
}

.admin-table {
  overflow: auto;

  table {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (min-width: 1024px) {
  .admin-table {
    height: calc(100vh - 280px);
  }
}

.mat-table {
  caption-side: bottom;
  border-collapse: collapse;
  width: 100%;

  > thead > tr > th {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 16px;
    height: 56px;
    text-align: left;
  }

  > tbody > tr > td {
    font-size: 14px;
    padding: 0 16px;
    height: 52px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  > tbody > tr:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  .mat-mdc-icon-button {
    width: 36px;
    height: 36px;
    padding: 6px;
    font-size: 20px;
  }
}

.mat-form {
  mat-form-field {
    display: block;
  }

  .mat-form-buttons {
    display: flex;
    margin-top: 13px;
  }

  .mat-form-buttons > * {
    margin-right: 6px;
  }
}

.mat-radio-group {
  .mat-radio-button + .mat-radio-button {
    margin-left: 16px;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (min-width: 992px) {
  .table-fixed {
    table-layout: fixed;
  }
}

.flex-spacer {
  flex-grow: 1;
}

.action-bar {
  @include mat.elevation(4);
  background: white;
  height: 50px;
  margin-bottom: 24px;
  padding: 7px 0;
  display: flex;
  line-height: 36px;
  font-size: 18px;

  .action-bar-title {
    padding: 0 16px;
  }

  .mat-mdc-button {
    margin-right: 16px;

    i {
      font-size: 105%;
    }

    .label {
      padding-left: 6px;
    }

    @media (max-width: $grid-mobile) {
      .label {
        display: none;
      }
    }
  }

  .mat-mdc-icon-button {
    top: -8px;
    margin-right: -16px;
  }
}

.action-bar-full-w {
  margin-left: -16px;
  margin-right: -16px;
}

.actions-cell {
  width: 1%;
  white-space: nowrap;
}

.toast {
  border-radius: 4px;
  display: block;
  margin-bottom: 24px;
  padding: 14px 16px;
  min-height: 48px;
  transform-origin: center;
  color: #ffffffb3;
  background: #323232;
  box-shadow:
    0 3px 5px -1px #0003,
    0 6px 10px #00000024,
    0 1px 18px #0000001f;

  &-success {
    background-color: #0f9960;
  }

  &-error {
    background-color: #db3737;
  }
}

.lock {
  height: 100vh;
  background: #e8eaf6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lock-content {
  padding: 16px;
  width: 500px;
  max-width: 500px;

  h1 {
    text-align: center;
    color: $app-color-primary;
  }

  h4 {
    text-align: center;
  }

  .lock-container {
    text-align: center;
    margin-top: 16px;
  }
}

.mat-card-avatar > i {
  color: $app-color-primary;
  font-size: 30px;
}

.mat-card-footer-button {
  padding: 16px;
  border-top: 1px solid #dfdfdf;
  cursor: pointer;
  color: $app-color-primary;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: right;
  text-transform: uppercase;

  i {
    font-size: 80%;
    margin-left: 10px;
  }
}

formly-field {
  display: block;
}

.mat-table-filter {
  th {
    padding: 8px !important;
    max-width: 1px;

    > div {
      height: 23px;
    }
  }

  .mat-form-field {
    display: block;
    top: -20px;
  }

  .mat-form-field-infix {
    width: auto;
  }

  .mat-form-field-suffix > i {
    display: block;
    padding: 7px;
    border-radius: 100%;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
  }
}
